<template>
  <div
    class="charityLogoContainer flex-grow-1 d-flex flex-column py-3 mt-2 text-center"
    :class="{'mn-height' : !mobileView}"
  >
    <div v-if="noClubSelected">
      <mdb-btn
        class="btn btn-md btn-outline m-0 mx-auto px-3 py-0 d-flex align-items-center"
        style="width: fit-content"
        @click="selectACharity()"
      >
        <mdb-icon class="iconSize" icon="hand-point-up" />
      </mdb-btn>
      <span>SELECT OUR PARTNER CHARITY</span>
    </div>
    <div
      v-else
      style="padding:10px;"
      class="noPadding"
    >
      <div style="padding:10px;border-radius:10px;background:white;">
        <img
          class="selectingClubLogoIMG"
          :src="club.ClubLogoURL" 
        >
      </div>
      <div class="clubNameSize">
        {{ club.Name }}
      </div>
      <mdb-btn
        class="btn btn-md btn-outline m-0 mx-auto px-3 py-0 mt-3"
        style="width: fit-content"
        @click="selectClub({ ClubId: 0 })"
      >
        REMOVE
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import basketService from '@/api-services/basket.service'

export default {
  name: 'CharityOrClubLogoContainer',
  data () {
    return {
      charityList: []
    }
  },
  computed: {
    ...mapGetters(['club', 'mobileView']),
    selectedClubId () { return this.club.ClubId },
    noClubSelected () { return this.club.ClubId === 0 || this.club.ClubId === -1 }
  },
  methods: {
    async getCharities () {
      this.loading = true
      try {
        const onlyCharities = true
        const res = await basketService.getClubs('all_clubs', onlyCharities)
        this.charityList = res.data
        this.$store.commit('storeClub', res.data[0])
      } catch {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      this.loading = false
    },
    selectACharity () {
      this.getCharities()
    },
    selectClub (club) {
      this.$store.commit('storeClub', club)
    }
  }
}
</script>

<style scoped>
.charityLogoContainer {
  font-size: clamp(1.5rem, 2vw, 2rem);
  border: 3px solid var(--pr-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.charityLogoContainer div {
  display: flex;
  flex-direction: column;
}
.iconSize {
  font-size: 1.2rem;
}
.selectingClubLogoIMG {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin: 0 auto 10px auto;
}
.mn-height {
  min-height:372px;
}
</style>
